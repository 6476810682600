<template>
  <div class="project-list">
    <div class="project-list-header">
      <CategorySearch :source="filterSource" @change="search" />
      <a-dropdown placement="bottomRight">
        <a-button type="primary">添加</a-button>
        <template #overlay>
          <a-menu>
            <a-menu-item @click="handleAdd">
              MQTT网关
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
    <BasicTable ref="tableRef" v-bind="tableConfig" @page-change="pageChange">
      <template #slotID="{ row }">
        <router-link
          :to="{
            path: `/IoTManage/gateway/detail/${row.bizId}`,
            state: { tabName: row.name },
          }"
        >
          {{ row.id }}
        </router-link>
      </template>
      <template #slotProduct="{ row }">
        {{ row.productNames.join("，") }}
      </template>
      <template #slotServer="{ row }">
        {{ row.serverName }}
        <a-tooltip placement="left" color="#fff" @visibleChange="(info)=>handleGetInfo(info, row.serverId)">
          <template #title>
            <div class="tip-wrapper">
              <section class="title">
                {{ row.serverName }}信息：
              </section>
              <section class="content">
                <div>CPU使用：{{ currentServeInfo.cpuUsed }} %</div>
                <div>可用内存：{{ currentServeInfo.memoryAvailable }} G</div>
              </section>
            </div>
          </template>
          <info-circle-outlined :style="{color:'#0256ff',cursor:'pointer'}"/>
        </a-tooltip>
        
      </template>
    </BasicTable>
  </div>
  <editModal
    v-if="modalVisible"
    v-model:visible="modalVisible"
    :detail="currentRow"
    :type="modalType"
    @success="editSuccess"
  ></editModal>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import CategorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { BasicTable } from "@/components/basic/table";
import { Modal, message } from "ant-design-vue";
import { apiGateWay } from "@/api/gateway.js";
import editModal from "./components/editModal";

// let isLoad = false;
// onMounted(() => {
//   tableRef.value.paginationProps.pageSize = 20;
//   isLoad = true;
//   initTableData();
// });
// onActivated(() => {
//   if (!isLoad) {
//     initTableData();
//   } else {
//     isLoad = false;
//   }
// });
onMounted(() => {
  tableRef.value.paginationProps.pageSize = 20;
  initTableData();
});

const filterSource = ref([
  {
    label: "网关名称",
    type: "input",
    bindKey: "name",
    placeholder: "请输入",
  },
  {
    label: "协议名称",
    type: "input",
    bindKey: "protocolName",
    placeholder: "请输入",
  },
  {
    label: "状态",
    type: "select",
    bindKey: "status",
    options: [
      {
        label: "启动",
        value: "01",
      },
      {
        label: "停止",
        value: "02",
      },
    ],
  },
]);
let searchData = {};
const search = (data) => {
  searchData = data;
  tableRef.value.paginationProps.current = 1;
  initTableData();
};

const tableRef = ref();
const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: true,
  isPageBottom: true,
  remote: false,
  loading: false,
  scroll: {
    scrollToFirstRowOnChange: true,
    y: "calc(100vh - 280px)",
  },
  columns: [
    {
      title: "ID",
      type: "customize",
      slotName: "slotID",
      key: "id",
      width: 80,
    },
    {
      title: "网关名称",
      type: "text",
      key: "name",
      width: 100,
      ellipsis: true,
    }, 
    {
      title: "所属项目",
      type: "text",
      key: "projectBizName",
      width: 120,
    },
    {
      title: "运行服务器",
      type: "customize",
      slotName: "slotServer",
      width: 100,
      ellipsis: true,
    },
    {
      title: "协议名称",
      type: "text",
      key: "protocolName",
      width: 60,
    },
    {
      title: "状态",
      type: "text",
      key: "statusName",
      width: 60,
    },
    {
      title: "接入产品",
      type: "customize",
      slotName: "slotProduct",
      width: 140,
    },
    {
      title: "描述",
      type: "text",
      key: "description",
      width: 120,
    },
  ],
  action: {
    title: "操作",
    width: 50,
    fixed: "right",
    items: [
      {
        type: "icon",
        icon: "FormOutlined",
        iconTip: "修改",
        onClick: (row) => {
          showModal(row);
        },
      },
      {
        icon: "DeleteOutlined",
        iconTip: "删除",
        type: "icon",
        onClick: (row) => {
          handleDelete(row.bizId, row);
        },
      },
    ],
  },
});
const initTableData = async () => {
  const { current, pageSize } = tableRef.value.paginationProps;
  const params = {
    pageNo: current,
    pageSize,
    ...searchData,
  };
  tableConfig.loading = true;
  try {
    tableRef.value.dataSourceRef = [];
    const res = await apiGateWay.list(params);
    const { records, total } = res?.result ?? {};
    tableConfig.loading = false;
    tableRef.value.dataSourceRef = records;
    tableRef.value.paginationProps.total = total;
  } catch (error) {
    tableConfig.loading = false;
    console.log(error);
  }
};
const pageChange = (pageIndex) => {
  tableRef.value.paginationProps.current = pageIndex;
  initTableData();
};

const currentServeInfo = ref({})
const handleGetInfo = async (visible,id)=>{
  if(visible){
    let {result} = await apiGateWay.getServeDetail(id)
    currentServeInfo.value = result || {}
  }
}

const modalVisible = ref(false);
const currentRow = ref(null);
const modalType = ref("add");
const showModal = (row) => {
  if(row.status === '01'){
    message.warning('网关启动状态，禁止修改！请先停止网关，再操作！')
    return;
  }
  if(row.protocolType === '99'){
    message.warning('定制协议类型网关，禁止修改！')
    return;
  }
  currentRow.value = row;
  modalVisible.value = true;
  modalType.value = "edit";
};
const handleAdd = () => {
  modalVisible.value = true;
  modalType.value = "add";
};
const editSuccess = () => {
  initTableData();
};

const handleDelete = (id, row) => {
  if(row.status === '01'){
    message.warning('网关启动状态，禁止删除！请先停止网关，再操作！')
    return;
  }
  if(row.protocolType === '99'){
    message.warning('定制协议类型网关，禁止删除！')
    return;
  }
  Modal.confirm({
    title: "提示",
    content: "是否删除该条数据？",
    okType: "danger",
    onOk: () => {
      return new Promise((resolve, reject) => {
        apiGateWay
          .delete(id)
          .then(() => {
            message.success("删除成功");
            initTableData();
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    onCancel() {
      console.log("Cancel");
    },
  });
};
</script>
<style lang="less" scoped>
.project-list {
  height: 100%;
  padding: 16px 20px;
  background: #fff;
  &-header {
    display: flex;
    justify-content: space-between;
  }
  &-table-button {
    line-height: 1;
    height: auto;
  }
}
.tip-wrapper{
  color: #333;
  .title{
    margin-bottom: 10px;
    border-bottom: 1px solid #fff;
    font-size: 14px;
    border-bottom: 1px solid #cdcdcd;
  }
  .content{
    font-size: 14px;
    color: #888;
  }
}
</style>
